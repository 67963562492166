<template>
  <div class="background" ref="card">
    <a-card class="card">
      <div>
        <div style="text-align: center; padding-top: 40px">
          <img
            :src="logoItem.logo_image ? logoItem.logo_image_item.file : logo"
            width="100"
            style="cursor: pointer; margin-top: 16px"
            @click="backIndex"
          />
        </div>
        <div class="title" style="margin-top: 16px">
          <span style="cursor: pointer; font-size: 24px" @click="backIndex">
            {{ logoItem.logo_title && logoItem.logo_title.length > 0 ? logoItem.logo_title : "盒木" }}进销存
          </span>
          <span style="font-size: 16px; margin-left: 4px">V2.2</span>
        </div>
        <div style="text-align: center; font-size: 16px; color: #1890ff; margin-top: 8px; margin-bottom: 24px">
          <span>智慧工厂管理系统</span>
        </div>
      </div>
      <router-view />
    </a-card>
  </div>
</template>

<script>
export default {
  name: "UserLayout",
  data() {
    return {
      logo: require("@/assets/logo.png"),
    };
  },
  computed: {
    logoItem() {
      return this.$store.state.system.logoItem;
    },
  },
  methods: {
    backIndex() {
      window.open("http://www.himool.com", "_self");
    },
  },
};
</script>

<style scoped>
.card {
  width: 520px;
  border-radius: 8px;
  padding-bottom: 64px;
  margin: auto;
}

.background {
  min-height: 100%;
  padding: 10vh 0;
  background: #f0f2f5 url("~@/assets/background.svg") no-repeat 50%;
}

.title {
  text-align: center;
  font-size: 32px;
  color: #1890ff;
  margin-top: 8px;
  font-weight: bold;
}
</style>
